<template>
  <div class="container with-price-box">
    <div>
      <h1>Iberico Front Leg (Paleta)</h1>
      <p>
        This is the front leg of the Iberico pig. It is smaller than the back leg (ham) and has a different shape.
      </p>
      <p>The total weight is between 4 and 6 kilogram. Families in Spain usually have such a leg in the kitchen and
        over the holidays eat it up very quickly.</p>
      <p>
        As the pigs have fattened themselves on sweet acorns the ham has a distinctive nutty flavor that resembles the
        flavor of the acorns. It is a piece of Dehesa on your table. It also goes well with a glass of red wine.
      </p>

      <p>Here is a video that shows how the pigs have been raised and how the ham is made:</p>

      <CookieControlledContent>
        <iframe data-cy="youtubeVideo" class="youtube-video"
          src="https://www.youtube.com/embed/1xHDWjk90sc?si=QCoQir0t9T8tUcsI" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </CookieControlledContent>

      <picture v-if="!$cookieConsent.accepted()">
        <a href="/img/shop/2023-Ham-Promo-1080.jpg"><img src="@/assets/images/2023-Ham-Promo-250.jpg" /></a>
      </picture>


    </div>
    <PriceBox product="c55eb01b-0d1e-4f9d-9af6-7d16e6678496" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'
import CookieControlledContent from '@/components/CookieControlledContent.vue'

export default {
  name: "LegOfHam",
  components: {
    PriceBox, CookieControlledContent
  },
}
</script>
